<div class="flex flex-col flex-grow container">
  <div class="flex mb-3">
    <h1 class="mb-0 text-lg title">{{ title }}</h1>
    <i
      *ngIf="limparProdutos"
      class="flex icon-delete_outline leading-none cursor-pointer vip-color-error-main ml-auto icon"
      data-cy="delete-button-remover-carrinho"
      (click)="onDeleteCarrinho()"
      ><span class="text-error text-xs">Limpar Produtos</span></i
    >
  </div>
  <div
    class="relative"
    [ngClass]="{
      '-mx-3': !swiperHasArrowsButtons,
      'px-2': swiperHasArrowsButtons,
    }"
  >
    <swiper id="swiper" [config]="swiperConfig" [navigation]="true">
      <ng-template
        swiperSlide
        *ngFor="let departamento of departamentos; let i = index"
      >
        <vip-tab-item
          label="{{ departamento }}"
          data-cy="tab-compra"
          class="text-sm grid"
          (tabClick)="selectedClick(i, $event)"
          [selected]="i === selectedIndex"
          [isDesktop]="true"
          [noBorder]="true"
        >
        </vip-tab-item>
      </ng-template>
    </swiper>
    <hr class="vip-divider-swiper" />
  </div>
  <div class="grid-container">
    <div class="grid-item text-sm">Produto</div>
    <div *ngIf="showPrecoUnitario" class="grid-item text-sm">
      Preço Unitário
    </div>
    <div class="grid-item text-sm">Quantidade</div>
    <div class="grid-item text-sm">Total</div>
  </div>
  <hr class="vip-divider mt-1" />
  <div
    class="scrollable-section scroll desktop-cart-max-height overflow-y-auto custom-scrollbar"
    #scrollContainer
  >
    @if (produtosBrindes().length > 0) {
      <div class="header text-sm mt-2 py-1 px-4" id="departamento_brinde">
        {{ 'Brinde' + (produtosBrindes().length > 1 ? 's' : '') }}
      </div>

      @for (brinde of produtosBrindes(); track $index) {
        <vip-card-produto-lista-wide
          [produtoBrinde]="brinde"
          [isDesktop]="true"
        />
      }
    }
    <div
      *ngFor="
        let itemsAgrupados of itensAgrupadosPorDepartamento;
        let i = index;
        trackBy: trackByItensDepartamentos
      "
    >
      <div class="header text-sm mt-2 py-1 px-4" id="departamento_{{ i }}">
        {{
          itemsAgrupados.departamento ? itemsAgrupados.departamento : 'Combo'
        }}
      </div>
      <div
        *ngFor="
          let item of itemsAgrupados.itens;
          let i = index;
          let last = last;
          trackBy: trackByItens
        "
        class="mt-2 first:mt-0"
      >
        <vip-card-produto-lista-wide
          [produto]="item"
          [showUnidade]="true"
          [disabled]="disabled"
          [last]="last"
          [showIconDelete]="showIconDelete"
          [showPrecoUnitario]="showPrecoUnitario"
          (produtoChanged)="produtoChanged.emit($event)"
          (deleteItemClick)="deleteItemClick.emit($event)"
          [isDesktop]="true"
        >
        </vip-card-produto-lista-wide>
      </div>
    </div>
  </div>
</div>
